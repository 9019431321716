.resource-bar-container {
    width: 200px;
    border: 1px solid #333;
    background-color: #f0f0f0;
    border-radius: 4px;
    padding: 4px;
    margin-top: 10px;
  }
  
  .resource-bar {
    width: 100%;
    height: 20px;
    background-color: #ccc;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 5px;
  }
  
  .progress-bar {
    height: 100%;
    transition: width 1s linear; /* Smoothly transition the width */
  }
  