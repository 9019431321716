body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: #f0f0f0;
}

.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 80px);
  gap: 10px;
  margin-bottom: 20px;
}

.tile {
  width: 80px;
  height: 80px;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.mole-1 {
  background-color: #333;
  color: #fff;
}

.mole-2 {
  background-color: #6dbb1a;
  color: #fff;
}

.mole-3 {
  background-color: #7d2ac2;
  color: #fff;
}

.mole-4 {
  background-color: #1a8abd;
  color: #fff;
}

.mole-whacked {
  background-color: red;
  color: #fff;
}

.info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.score,
.combo-container,
.multiplier,
.combo-requirement {
  font-size: 20px;
}

.combo-icon {
  width: 30px;
  height: 30px;
}

.buttons-container {
  display: flex;
  gap: 10px;
}

.popup-message {
  position: absolute;
  font-size: 24px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  pointer-events: none;
  opacity: 0;
  transform: translateY(0);
  transition: transform 0.2s, opacity 0.2s;
}

.show-popup {
  opacity: 1;
  transform: translateY(-50px);
}
